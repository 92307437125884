import { Card, Skeleton, Space } from 'antd';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import { useEffect, useState } from 'react';
import SelfStudyApiService from '../../api/SelfStudyApiService';
import SelfStudySectionComponentResponseApiService from '../../api/SelfStudySectionComponentResponseApiService';
import SelfStudyTemplateApiService from '../../api/SelfStudyTemplateApiService';
import Routes from '../../config/Routes';
import AcademicYearStatusType from '../../consts/AcademicYearStatusType';
import FeatureFlag from '../../consts/FeatureFlag';
import SelfStudyResponseStatusType from '../../consts/SelfStudyResponseStatusType';
import SelfStudyResponseType from '../../consts/SelfStudyResponseType';
import * as GetSelfStudySectionComponentResponseByTypeHandler from '../../handlerModels/GetSelfStudySectionComponentResponseByTypeHandler';
import * as GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler from '../../handlerModels/GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler';
import * as GetSelfStudyWithFreeTextQuestionHandler from '../../handlerModels/GetSelfStudyWithFreeTextQuestionHandler';
import SelfStudyDTO from '../../models/SelfStudyDTO';
import SelfStudyResponseDTO from '../../models/SelfStudyResponseDTO';
import SelfStudySectionComponentResponseDTO from '../../models/SelfStudySectionComponentResponseDTO';
import SelfStudySectionComponentTemplateDTO from '../../models/SelfStudySectionComponentTemplateDTO';
import SelfStudySectionResponseDTO from '../../models/SelfStudySectionResponseDTO';
import BaseFormProps from '../../redux/bases/BaseFormProps';
import AuthorizationUtil from '../../utils/AuthorizationUtil';
import Guid from '../../utils/Guid';
import ValidationRuleUtil from '../../utils/ValidationRuleUtil';
import ValidationUtil from '../../utils/ValidationUtil';
import FileUpload from '../FileUpload';
import RichTextEditor from '../inputs/RichTextEditor';
import { UploadFile } from 'antd/lib/upload/interface';
import Html from 'react-pdf-html';
import { Text, View } from '@react-pdf/renderer';

interface SelfStudyFreeTextQuestionProps extends BaseFormProps {
  handleFileUpload?: ((componentTemplateIndex: number, sectionTemplateIndex: number, value: UploadFile | undefined) => void) | undefined;
  handleFileRemoved?: ((componentTemplateIndex: number, sectionTemplateIndex: number, value: UploadFile | undefined) => void) | undefined;
  academicYear?: number;
  selfStudyResponseId?: string;
  selfStudyId?: string;
  componentIndex: number;
  sectionIndex: number;
  readonly?: boolean;
  disable?: boolean;
  previewComponent?: boolean;
  pdfView?: boolean;
  renderComplete?: (sectionIndex: number,componentIndex: number, value: boolean) => void;
}

const SelfStudyFreeTextQuestion = React.forwardRef((props: SelfStudyFreeTextQuestionProps) => {
  const getComponentFormItems = () => {
    return new Map<string, FormItemProps>()
      .set(SelfStudySectionComponentTemplateDTO.primaryText, {
        name: [props.componentIndex, SelfStudySectionComponentTemplateDTO.primaryText],
      })
      .set(SelfStudySectionComponentResponseDTO.selfStudySectionComponentResponseResources, {
        name: [props.componentIndex, SelfStudySectionComponentResponseDTO.selfStudySectionComponentResponseResources],
      })
      .set(SelfStudySectionComponentResponseDTO.responseText, {
        required: selfStudyResponse?.selfStudyReponseTypeId == SelfStudyResponseType.DOE  || (!props.disable && (props.readonly)) ? false : true,
        name: [props.componentIndex, SelfStudySectionComponentResponseDTO.responseText],
        label: 'Response',
        rules: selfStudyResponse?.selfStudyReponseTypeId == SelfStudyResponseType.DOE || props.readonly ? [] : [ValidationRuleUtil.required()]
      });
  }

  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState(props.fieldErrors);
  const [submitted, setSubmitted] = useState(props.submitted);
  const [selfStudyResponse, setSelfStudyResponse] = useState({} as SelfStudyResponseDTO);
  const [selfStudy, setSelfStudy] = useState({} as SelfStudyDTO);
  const [selfStudySectionComponentTemplate, setSelfStudySectionComponentTemplate] = useState({} as SelfStudySectionComponentTemplateDTO);
  const [educatorSelfStudySectionComponentResponse, setEducatorSelfStudySectionComponentResponse] = useState({} as SelfStudySectionComponentResponseDTO);
  const [rppSelfStudySectionComponentResponse, setRPPSelfStudySectionComponentResponse] = useState({} as SelfStudySectionComponentResponseDTO);
  const [doeSelfStudySectionComponentResponse, setDOESelfStudySectionComponentResponse] = useState({} as SelfStudySectionComponentResponseDTO);

  useEffect(() => {
    fetchData();
  }, [props.academicYear, props.selfStudyResponseId]
  )

  useEffect(() => {
    setFieldErrors(props.fieldErrors);
    setSubmitted(props.submitted);
  }, [props.submitted, props.fieldErrors])

  const fetchData = () => {
    setLoading(true);
    const loaders = [];

    if (props.academicYear != 0 &&
      props.selfStudyResponseId != Guid.Empty() &&
      !props.previewComponent) {
      loaders.push(loadSelfStudy(props.selfStudyResponseId ?? Guid.Empty()));
      if (props.selfStudyId && props.selfStudyId != Guid.Empty()) {
        loaders.push(loadSectionResponseByType(SelfStudyResponseType.INSTRUCTOR, setEducatorSelfStudySectionComponentResponse));
        loaders.push(loadSectionResponseByType(SelfStudyResponseType.RPP, setRPPSelfStudySectionComponentResponse));
        loaders.push(loadSectionResponseByType(SelfStudyResponseType.DOE, setDOESelfStudySectionComponentResponse));
      }

      loaders.push(loadSelfStudySectionComponentTemplate());

      Promise.all(loaders).then(() => {
        setLoading(false);
        if(props.renderComplete){
          props.renderComplete(props.sectionIndex ?? 0,props.componentIndex, true);
        }
      });
    }
    else if (props.previewComponent) {
      setLoading(false);
      if(props.renderComplete){
        props.renderComplete(props.sectionIndex ?? 0,props.componentIndex, true);
      }
    }
  }

  const loadSectionResponseByType = (type: number, callback: React.Dispatch<React.SetStateAction<SelfStudySectionComponentResponseDTO>>) => {
    const request = GetSelfStudySectionComponentResponseByTypeHandler.Request.create({
      selfStudyId: props.selfStudyId,
      selfStudyResponseTypeId: type,
      sectionIndex: props.sectionIndex,
      componentIndex: props.componentIndex
    });

    return SelfStudySectionComponentResponseApiService.getSelfStudySectionComponentResponseByType(request)
      .then((results: GetSelfStudySectionComponentResponseByTypeHandler.Result) => {
        if (results.selfStudySectionComponentResponse) {
          callback(results.selfStudySectionComponentResponse ?? SelfStudySectionResponseDTO.create());
        }
      });
  }

  const loadSelfStudy = (id: string) => {
    const request = GetSelfStudyWithFreeTextQuestionHandler.Request.create({
      selfStudyResponseId: id,
      academicYear: props.academicYear
    });

    return SelfStudyApiService.getSelfStudyWithFreeTextQuestion(request)
      .then((results: GetSelfStudyWithFreeTextQuestionHandler.Result) => {
        if (results.selfStudy) {
          setSelfStudy(results.selfStudy);
          setSelfStudyResponse(results.selfStudy.selfStudyResponses?.find(x => x.id == id) ?? SelfStudyResponseDTO.create());
        }
      });
  }

  const loadSelfStudySectionComponentTemplate = () => {
    const request = GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler.Request.create({
      academicYear: props.academicYear,
      selfStudySectionOrder: props.sectionIndex,
      selfStudySectionComponentOrder: props.componentIndex
    });

    return SelfStudyTemplateApiService.getSelfStudySectionComponentTemplateBySectionOrder(request)
      .then((results: GetSelfStudySectionTemplateBySectionOrderAndComponentOrderHandler.Result) => {
        if (results.selfStudySectionComponentTemplate) {
          setSelfStudySectionComponentTemplate(results.selfStudySectionComponentTemplate ?? SelfStudySectionComponentTemplateDTO.create());
        }
      });
  }

  const generateLinks = (files: any[]) => {
    const links: any[] | undefined = [];

    files.forEach((x: any) => {
      if (x != undefined) {
        links.push({
          uid: x.azureBlobStorageId,
          url: Routes.generateDownload(x.azureBlobStorageId ?? Guid.Empty()),
          name: x.azureBlobFile?.fileName
        });
      }
    });

    return links;
  }

  const getExistingDEFiles = () => {
    return generateLinks(doeSelfStudySectionComponentResponse?.selfStudySectionComponentResponseResources ?? []);
  }

  const getExistingInstructorsFiles = () => {
    return generateLinks(educatorSelfStudySectionComponentResponse.selfStudySectionComponentResponseResources ?? []);
  }

  const getExistingRPPFiles = () => {
    return generateLinks(rppSelfStudySectionComponentResponse.selfStudySectionComponentResponseResources ?? []);
  }

  const renderDistrictResponse = () => {
    if (selfStudyResponse.selfStudyReponseTypeId != SelfStudyResponseType.INSTRUCTOR || (selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR && props.readonly)) {
      return renderExistingResponse('District Response', educatorSelfStudySectionComponentResponse?.rating, educatorSelfStudySectionComponentResponse?.responseText ?? '', getExistingInstructorsFiles());
    }
  }

  const renderPdfDistrictResponse = () => {
    if (selfStudyResponse.selfStudyReponseTypeId != SelfStudyResponseType.INSTRUCTOR || (selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.INSTRUCTOR && props.pdfView)) {
      return renderPdfExistingResponse('District Response', educatorSelfStudySectionComponentResponse?.rating, educatorSelfStudySectionComponentResponse?.responseText ?? '');
    }
    return <></>
  }

  const renderRppResponse = () => {
    if (
      ((selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.DOE || (selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.RPP && props.readonly)) &&
        (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_RPP_RESPONSE, FeatureFlag.VIEW_RPP_RESPONSE, FeatureFlag.EDIT_DE_RESPONSE, FeatureFlag.VIEW_DE_CONSULTANT_RESPONSE]))) ||
      selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.REVISING ||
      selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.REVISIONSNEEDED ||
      selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.COMPLETE) {
      return renderExistingResponse('RPP Response', rppSelfStudySectionComponentResponse?.rating, rppSelfStudySectionComponentResponse?.responseText ?? '', getExistingRPPFiles());
    }
  }

  const renderPdfRppResponse = () => {
    if (
      ((selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.DOE || (selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.RPP && props.pdfView)) &&
        (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_RPP_RESPONSE, FeatureFlag.VIEW_RPP_RESPONSE, FeatureFlag.EDIT_DE_RESPONSE, FeatureFlag.VIEW_DE_CONSULTANT_RESPONSE]))) ||
      selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.REVISING ||
      selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.REVISIONSNEEDED ||
      selfStudyResponse.selfStudyResponseStatusTypeId == SelfStudyResponseStatusType.COMPLETE) {
      return renderPdfExistingResponse('RPP Response', rppSelfStudySectionComponentResponse?.rating, rppSelfStudySectionComponentResponse?.responseText ?? '');
    }
  }

  const renderDEResponse = () => {
    if ((selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.DOE && props.readonly && (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DE_RESPONSE, FeatureFlag.VIEW_DE_CONSULTANT_RESPONSE]))) ||
      selfStudy.academicYear?.academicYearStatusTypeId == AcademicYearStatusType.RELEASEDAPPROVALCOMMENTS) {
      return renderExistingResponse('DE Response', doeSelfStudySectionComponentResponse?.rating, doeSelfStudySectionComponentResponse?.responseText ?? '', getExistingDEFiles());
    }
  }

  const renderPdfDEResponse = () => {
    if ((selfStudyResponse.selfStudyReponseTypeId == SelfStudyResponseType.DOE && props.pdfView && (AuthorizationUtil.isAuthorized([FeatureFlag.EDIT_DE_RESPONSE, FeatureFlag.VIEW_DE_CONSULTANT_RESPONSE]))) ||
      selfStudy.academicYear?.academicYearStatusTypeId == AcademicYearStatusType.RELEASEDAPPROVALCOMMENTS) {
      return renderPdfExistingResponse('DE Response', doeSelfStudySectionComponentResponse?.rating, doeSelfStudySectionComponentResponse?.responseText ?? '');
    }
  }

  const handleFileUpload = (file: UploadFile | undefined) => {
    if (props.handleFileUpload) {
      props.handleFileUpload(props.componentIndex, props.sectionIndex, file);
    }
  }

  const handleFileRemoved = (file: UploadFile | undefined) => {
    if (props.handleFileRemoved) {
      props.handleFileRemoved(props.componentIndex, props.sectionIndex, file);
    }
  }

  const renderExistingResponse = (title: string, rating: number, comments: string, files: any) => {
    return (
      <Card title={title} type='inner' className='sub-inner-card' size='small'>
        <Space direction='vertical'>
          <RichTextEditor value={comments} readonly={true} />
          <FileUpload onUploaded={handleFileUpload} onRemoved={handleFileRemoved} readonly={true} disabled={true} existingList={files} style={{ width: 300 }} text={'Add supporting documents'} />
        </Space>
      </Card>
    )
  }

  const renderPdfExistingResponse = (title: string, rating: number, comments: string) => {
    const fontSizeFix = comments.replaceAll(new RegExp('ptpx','g'), 'px');
    const decimal = fontSizeFix.replaceAll(new RegExp('font-size:?[0-9]{1,2}px','g'), 'font-size:8px');
    const fontFamily = decimal.replaceAll(new RegExp('font-size:?[0-9]{1,2}.[0-9]{1,2}px','g'), 'font-size:8px');
    const finalText = fontFamily.replaceAll(new RegExp('font-family:','g'), '');
    return (
      <View style={{ border: '1pt solid #f0f0f0', margin: 10 }}>
        <Text style={{ padding: 5, fontSize: 10, border: '1pt solid #f0f0f0', backgroundColor: '#f0f0f0' }}>{title}</Text>
        <View style={{ marginLeft: 10, marginTop: 2 }}>
          <Html style={{ fontSize: 8 }}>{finalText}</Html>
        </ View>
      </View >
    )
  }

  const renderFormFields = () => {
    const componentIndex = props.componentIndex;
    let existingFiles: any[] | undefined = [];

    switch (selfStudyResponse.selfStudyReponseTypeId) {
      case SelfStudyResponseType.INSTRUCTOR:
        existingFiles = getExistingInstructorsFiles()
        break;
      case SelfStudyResponseType.RPP:
        existingFiles = getExistingRPPFiles()
        break;
      case SelfStudyResponseType.DOE:
        existingFiles = getExistingDEFiles()
        break;
    }

    if (!props.readonly) {
      const body =
        <Content>
          <FormItem
            {...getComponentFormItems().get(SelfStudySectionComponentResponseDTO.responseText)}
            {...ValidationUtil.getValidation(SelfStudySectionComponentResponseDTO.responseText + props.sectionIndex + componentIndex, fieldErrors, submitted)} >
            <RichTextEditor readonly={!props.disable && (props.readonly)} />
          </FormItem>

          <FormItem
            {...getComponentFormItems().get(SelfStudySectionComponentResponseDTO?.selfStudySectionComponentResponseResources)}
            {...ValidationUtil.getValidation(SelfStudySectionComponentResponseDTO.selfStudySectionComponentResponseResources + componentIndex, fieldErrors, submitted)} >
            <FileUpload onUploaded={handleFileUpload} onRemoved={handleFileRemoved} disabled={props.disable || props.readonly} existingList={existingFiles} style={{ width: 300 }} text={'Add supporting documents'} />
          </FormItem>

        </Content>;

      return body
    }

    return null;
  }

  if (props.pdfView) {
    const text = selfStudySectionComponentTemplate?.primaryText ?? '';
    const decimal = text.replaceAll(new RegExp('font-size:?[0-9]{1,2}px','g'), 'font-size:10px');
    const fontFamily = decimal.replaceAll(new RegExp('font-size:?[0-9]{1,2}.[0-9]{1,2}px','g'), 'font-size:10px');
    const finalText = fontFamily.replaceAll(new RegExp('font-family:','g'), '');
    return (
      <View style={{ paddingBottom: '10pt' }}>
        <View style={{ border: '1pt solid #f0f0f0', margin: 10 }} >
          <View style={{ margin: 10 }}>
            <Html style={{ fontSize: 10 }}>{finalText}</Html>
            {renderPdfDistrictResponse()}
            {renderPdfRppResponse()}
            {renderPdfDEResponse()}
            {props.previewComponent ? <Text style={{ margin: 10, fontSize: 10, }}> Response</Text>
              : null}
          </ View>
        </ View>
      </View>
    )

  }
  else if (loading) {
    return <Skeleton active />
  }
  else {
    return (
      <Card type='inner' >
        <Space direction='vertical'>
          <RichTextEditor readonly={true} value={selfStudySectionComponentTemplate?.primaryText} />

          {renderDistrictResponse()}
          {renderRppResponse()}
          {renderDEResponse()}

          {renderFormFields()}
        </Space>
      </Card>
    );
  }
})

SelfStudyFreeTextQuestion.displayName = 'SelfStudyFreeTextQuestion';

export default SelfStudyFreeTextQuestion;