import { Alert, message, Select, Skeleton, Space } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import FormItem, { FormItemProps } from 'antd/lib/form/FormItem';
import * as React from 'react';
import DistrictApiService from '../../../api/DistrictApiService';
import SecondaryCipNumberApiService from '../../../api/SecondaryCipNumberApiService';
import SecondaryProgramApiService from '../../../api/SecondaryProgramApiService';
import ThirdPartyCredentialDisplay from '../../../components/displays/ThirdPartyCredentialDisplay';
import FeatureFlag from '../../../consts/FeatureFlag';
import * as GetDistrictsHandler from '../../../handlerModels/GetDistrictsHandler';
import * as GetSecondaryCipNumbresHandler from '../../../handlerModels/GetSecondaryCipNumbresHandler';
import * as GetSecondaryProgramDetailsHandler from '../../../handlerModels/GetSecondaryProgramDetailsHandler';
import * as SaveSecondaryProgramDetailsHandler from '../../../handlerModels/SaveSecondaryProgramDetailsHandler';
import * as SearchForSecondaryCipNumbersHandler from '../../../handlerModels/SearchForSecondaryCIpNumbersHandler';
import ApprovalStatusTypeDTO from '../../../models/ApprovalStatusTypeDTO';
import DistrictDTO from '../../../models/DistrictDTO';
import SecondaryCipNumberDTO from '../../../models/SecondaryCipNumberDTO';
import SecondaryProgramDTO from '../../../models/SecondaryProgramDTO';
import BaseFormProps from '../../../redux/bases/BaseFormProps';
import BaseFormState from '../../../redux/bases/BaseFormState';
import CurrentUser from '../../../utils/CurrentUser';
import Guid from '../../../utils/Guid';
import LookupsUtil from '../../../utils/LookupsUtil';
import ValidationRuleUtil from '../../../utils/ValidationRuleUtil';
import ValidationUtil from '../../../utils/ValidationUtil';
import AuthorizedContent from '../../AuthorizedContent';
import ResetButton from '../../buttons/ResetButton';
import SaveButton from '../../buttons/SaveButton';
import AutoCompleteInput, { AutoCompleteInputOption } from '../../general/AutoCompleteInput';
import ValueLabel from '../../general/ValueLabel';
import Dropdown from '../../inputs/Dropdown';
import ReadableNumberInput from '../../inputs/ReadableNumberInput';

interface SecondaryProgramDetailsFormState extends BaseFormState {
  secondaryProgram: SecondaryProgramDTO;
  districts: DistrictDTO[];
  cipNumbers: SecondaryCipNumberDTO[];
  searching: boolean;
  approvalStatusTypes: ApprovalStatusTypeDTO[];
}

interface SecondaryProgramDetailsFormProps extends BaseFormProps {
  secondaryProgramId: string;
  academicYear?: number;
  readonly?: boolean;
  fromDataReview?: boolean;
  onSave?: (secondaryProgramId: string) => void;
}

class SecondaryProgramDetailsForm extends React.Component<SecondaryProgramDetailsFormProps, SecondaryProgramDetailsFormState> {
  private readonly _formRef = React.createRef<FormInstance>();
  private readonly YEAR_TO_HIDE = 2023;
  private readonly _formItems = new Map<string, FormItemProps>()
    .set(SecondaryProgramDTO.secondaryProgramId, {
      required: true,
      name: SecondaryProgramDTO.secondaryProgramId,
      label: 'Identifier',
      rules: [
        ValidationRuleUtil.required(),
        ValidationRuleUtil.onlyNumericCharacters()
      ],
    })
    .set(SecondaryProgramDTO.districtId, {
      required: true,
      name: SecondaryProgramDTO.districtId,
      label: 'District',
      rules: [
        ValidationRuleUtil.required()
      ],
    })
    .set(SecondaryProgramDTO.cipNumberId, {
      required: true,
      name: SecondaryProgramDTO.cipNumberId,
      label: 'CIP Number',
      rules: [
        ValidationRuleUtil.required()
      ],
    })
    .set(SecondaryProgramDTO.programStartYear, {
      name: SecondaryProgramDTO.programStartYear,
      label: 'Program Start Year',
    })
    .set(SecondaryProgramDTO.programEndYear, {
      name: SecondaryProgramDTO.programEndYear,
      label: 'Program End Year',
    })

  constructor(props: SecondaryProgramDetailsFormProps) {
    super(props);

    this.state = {
      secondaryProgram: SecondaryProgramDTO.create({
        id: undefined,
        secondaryProgramId: undefined,
        districtId: undefined,
        cipNumberId: undefined,
        stateAssistanceEligibleYear: undefined,
        stateAssistanceEligible: undefined,
        studentStatusTypeId: undefined,
        approvalStatusId: undefined
      }),
      districts: [],
      cipNumbers: [],
      searching: false,
      approvalStatusTypes: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: SecondaryProgramDetailsFormProps) {
    if (this.props != prevProps) {
      this.fetchData();
    }
  }

  private fetchData = () => {
    this.setState({ loading: true });
    const loaders = [];
    loaders.push(this.loadSecondaryProgram());

    if (!this.state.districts || this.state.districts.length == 0 || this.state.districts[0].academicYear != this.props.academicYear) {
      loaders.push(this.loadDistricts());
    }

    if (!this.state.cipNumbers || this.state.cipNumbers.length == 0 || this.state.cipNumbers[0].academicYear != this.props.academicYear) {
      loaders.push(this.loadCipNumbers());
    }

    if (!this.state.approvalStatusTypes || this.state.approvalStatusTypes.length == 0) {
      loaders.push(this.loadApprovalStatusTypes());
    }

    Promise.all(loaders).then(() => {
      this.setState({ loading: false });
      this.resetForm();
    });
  }

  private loadSecondaryProgram() {
    if (!this.state.loading) {
      if (this.props.secondaryProgramId == Guid.Empty() || this.props.isNew) {
        this.loadNew();
      }
      else {
        this.loadExisting();
      }
    }
  }

  public resetForm = () => {
    this._formRef.current?.resetFields();
    this.setState({ altered: false });
  }

  private loadExisting = () => {
    if (this.props.secondaryProgramId && this.props.academicYear) {
      const request = GetSecondaryProgramDetailsHandler.Request.create({
        id: this.props.secondaryProgramId
      });
      SecondaryProgramApiService.getSecondaryProgramDetails(request)
        .then((results: GetSecondaryProgramDetailsHandler.Result) => {
          if (results && results.secondaryProgram && results.secondaryProgram.secondaryCipNumber) {
            this.setState({
              cipNumbers: [results.secondaryProgram.secondaryCipNumber],
              secondaryProgram: results.secondaryProgram ?? SecondaryProgramDTO.create({
                id: this.props.secondaryProgramId == Guid.Empty() ? undefined : this.props.secondaryProgramId,
                secondaryProgramId: undefined,
                districtId: undefined,
                cipNumberId: undefined,
                studentStatusTypeId: undefined,
                approvalStatusId: undefined
              })
            });
          }

          this.resetForm();
        })
        .catch(() => {
          this.setState({ error: true });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  private loadApprovalStatusTypes = () => {
    return LookupsUtil.getAll<ApprovalStatusTypeDTO>(ApprovalStatusTypeDTO.className)
      .then((results: ApprovalStatusTypeDTO[]) => {
        if (results) {
          this.setState({ approvalStatusTypes: results ?? [] });
        }
      }).catch(() => {
        this.setState({ error: true });
      });
  }

  private loadDistricts = () => {
    const request = GetDistrictsHandler.Request.create({
      academicYearId: this.props.academicYear ?? 0
    });

    DistrictApiService.getDistricts(request)
      .then((results: GetDistrictsHandler.Result) => {
        if (results) {
          this.setState({
            districts: results.districts ?? []
          });
        }

        this.resetForm();
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadCipNumbers = () => {
    const request = GetSecondaryCipNumbresHandler.Request.create({
      academicYear: this.props.academicYear
    })
    SecondaryCipNumberApiService.getSecondaryCipNumbers(request)
      .then((results: GetSecondaryCipNumbresHandler.Result) => {
        if (results) {
          this.setState({
            cipNumbers: results.secondaryCipNumbers ?? []
          });
        }

        this.resetForm();
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  private loadNew = () => {
    this.setState({
      loading: false, secondaryProgram: SecondaryProgramDTO.create({
        id: this.props.secondaryProgramId == Guid.Empty() ? undefined : this.props.secondaryProgramId,
        secondaryProgramId: undefined,
        districtId: undefined,
        cipNumberId: undefined,
        studentStatusTypeId: undefined,
        approvalStatusId: undefined
      })
    });
  }

  private handleChange = () => {
    this.setState({ altered: true });
  }

  public submit = () => {
    this._formRef.current?.validateFields().then(
      () => this.handleSubmit(),
      () => this.setState({ error: true })
    );
  }

  private handleSubmit = () => {
    this.setState({ submitting: true });

    const request = SaveSecondaryProgramDetailsHandler.Request.create({
      secondaryProgramId: this.props.secondaryProgramId,
      secondaryProgram: SecondaryProgramDTO.create({
        ...(this._formRef ? (this._formRef.current as any).getFieldsValue() : null),
        academicYear: this.props.academicYear,
      }),
    });
    SecondaryProgramApiService.saveSecondaryProgramDetails(request)
      .then((result: SaveSecondaryProgramDetailsHandler.Result) => {
        this.setState({ submitted: true });
        if (result?.succeeded) {
          this.setState({ secondaryProgram: result.secondaryProgram ?? SecondaryProgramDTO.create(), fieldErrors: undefined, error: false });

          message.success('Saved');

          if (this.props.onSave && result.secondaryProgram?.id) {
            this.props.onSave(result.secondaryProgram.id);
            this.resetForm();
          }

        }
        else {
          this.setState({
            error: !result?.succeeded,
            message: result?.errors.join('\n'),
            fieldErrors: result?.fieldErrors
          });
          message.error('Program could not be saved');
          this.setState({ loading: false, submitting: false });
        }
      })
      .catch((results: any) => {
        this.setState({ error: results, loading: false, submitting: false });
        message.error('Programcould not be saved');
      })
      .finally(() => {
        this.setState({ loading: false, submitting: false });
      });
  }

  private handleCipNumberSearch = (value: string) => {
    if (value.length > 1) {
      const request = SearchForSecondaryCipNumbersHandler.Request.create(
        {
          searchString: value,
          academicYear: this.props.academicYear
        });

      this.setState({ searching: true });

      SecondaryCipNumberApiService.searchForSecondaryCipNumbers(request)
        .then((x: SearchForSecondaryCipNumbersHandler.Result) => {

          this.setState({ cipNumbers: x.cipNumbers ?? [], searching: false });
        });
    }
  }

  private handleCipNumberSelect = () => {
    // no-op
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active={true} />;
    }

    const isPublic = CurrentUser.Get() == null;
    const cipNumbers = this.state.cipNumbers.sort((a, b) => a.code?.localeCompare(b.code ?? '') ?? 0).map(x => {
      return {
        value: x.id,
        display: x.display
      } as AutoCompleteInputOption;
    });

    return (
      <Space size="small" direction="vertical">
        {this.renderErrors()}
        <Form
          ref={this._formRef}
          layout="vertical"
          initialValues={this.state.secondaryProgram}
          onValuesChange={this.handleChange}
          onFinish={this.handleSubmit}
          requiredMark={!isPublic}>

          <Space size="small" direction="vertical">
            <FormItem
              className={'description-container'}
              {...this._formItems.get(SecondaryProgramDTO.secondaryProgramId)}
              {...ValidationUtil.getValidation(SecondaryProgramDTO.secondaryProgramId, this.state.fieldErrors, this.state.submitted)}>
              <ReadableNumberInput readonly={isPublic} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
            </FormItem>

            <FormItem
              className={'description-container'}
              {...this._formItems.get(SecondaryProgramDTO.districtId)}
              {...ValidationUtil.getValidation(SecondaryProgramDTO.districtId, this.state.fieldErrors, this.state.submitted)}>
              {isPublic ? <ValueLabel text={this.state.districts.find(x => x.id == this.state.secondaryProgram.districtId)?.name} /> :
                <Dropdown readOnly={isPublic} dropdownMatchSelectWidth={false} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} >
                  {this.state.districts.sort((a, b) => a.districtId - b.districtId).map(x => this.renderDistrict(x))}
                </Dropdown>}
            </FormItem>

            <FormItem
              className={'description-container'}
              {...this._formItems.get(SecondaryProgramDTO.cipNumberId)}
              {...ValidationUtil.getValidation(SecondaryProgramDTO.cipNumberId, this.state.fieldErrors, this.state.submitted)}>
              {isPublic ? <ValueLabel text={this.state.cipNumbers.find(x => x.id == this.state.secondaryProgram.cipNumberId)?.code} /> :
                <AutoCompleteInput onSelect={this.handleCipNumberSelect} options={cipNumbers ?? []} searching={this.state.searching} onSearch={this.handleCipNumberSearch} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
              }
            </FormItem>

            <FormItem
              className={'description-container'}
              {...this._formItems.get(SecondaryProgramDTO.programStartYear)}
              {...ValidationUtil.getValidation(SecondaryProgramDTO.programStartYear, this.state.fieldErrors, this.state.submitted)}>
              <ReadableNumberInput readonly={isPublic} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
            </FormItem>

            <FormItem
              className={'description-container'}
              {...this._formItems.get(SecondaryProgramDTO.programEndYear)}
              {...ValidationUtil.getValidation(SecondaryProgramDTO.programEndYear, this.state.fieldErrors, this.state.submitted)}>
              <ReadableNumberInput readonly={isPublic} disabled={!this.props.isEditing || this.state.loading || this.state.submitting} />
            </FormItem>

            {(this.props.academicYear ?? 0) <= this.YEAR_TO_HIDE ? null : <ThirdPartyCredentialDisplay
              thirdPartyCredentials={this.state.secondaryProgram.thirdPartyCredentials ?? []}
              isSecondaryProgram={true}
              readonly={!this.props.isEditing || this.state.loading || this.state.submitting}
              submitted={this.state.submitted}
              fieldErrors={this.state.fieldErrors} />}

            {this.renderSave()}
          </Space>
        </Form>
      </Space >
    );
  }

  renderApprovalStatusTypes(approvalStatusType: ApprovalStatusTypeDTO) {
    if (approvalStatusType.id) {
      return <Select.Option title={approvalStatusType.name ?? ''} key={approvalStatusType.id ?? Guid.Empty()} value={approvalStatusType.id ?? Guid.Empty()}>{approvalStatusType.name}</Select.Option>
    }
  }

  renderDistrict(district: DistrictDTO) {
    if (district.id) {
      return <Select.Option title={district.display ?? ''} key={district.id ?? Guid.Empty()} value={district.id ?? Guid.Empty()}>{district.display}</Select.Option>
    }
  }

  renderCipNumber(cipNumber: SecondaryCipNumberDTO) {
    if (cipNumber.id) {
      return <Select.Option title={cipNumber?.display?.toString() ?? ''} key={cipNumber.id ?? Guid.Empty()} value={cipNumber.id ?? Guid.Empty()}>{cipNumber.display}</Select.Option>
    }
  }

  renderSave() {
    if (this.props.isEditing && !this.props.fromDataReview) {
      return (
        <AuthorizedContent validFeatureFlags={[FeatureFlag.EDIT_SECONDARY_PROGRAM]}>
          <Space direction={'horizontal'} >
            <SaveButton disabled={!this.state.altered} saving={this.state.submitting} />
            <ResetButton disabled={!this.state.altered} onConfirm={this.resetForm} />
          </Space>
        </AuthorizedContent>
      );
    }
  }

  renderErrors() {
    if (this.state.error) {
      return <Alert type="error" message='Error' showIcon={true} description='There were errors submitting your request. Please review the fields below.' />;
    }
  }
}

export default SecondaryProgramDetailsForm;
