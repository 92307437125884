// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the ApiServices.tst template. 
// !!!! Do not modify this file or change may be lost !!!!
/* eslint-disable */
import BaseApi from './BaseApi';
import TableRequestDTO from '../models/TableRequestDTO';
import TableResponseDTO from '../models/TableResponseDTO';
import ThirdPartyCredentialTypeDTO from '../models/ThirdPartyCredentialTypeDTO';
import * as GetThirdPartyCredentialTypeDetailsHandler from '../handlerModels/GetThirdPartyCredentialTypeDetailsHandler';
import * as SaveThirdPartyCredentialTypeDetailsHandler from '../handlerModels/SaveThirdPartyCredentialTypeDetailsHandler';
import * as GetThirdPartyCredentialTypeHandler from '../handlerModels/GetThirdPartyCredentialTypeHandler';
import * as GetMaxSecondaryThirdPartyCredentialsHandler from '../handlerModels/GetMaxSecondaryThirdPartyCredentialsHandler';
import * as DeleteThirdPartyCredentialTypeHandler from '../handlerModels/DeleteThirdPartyCredentialTypeHandler';

export class ThirdPartyCredentialTypeApiService extends BaseApi {

    // post: api/thirdPartyCredentialType
    public getThirdPartyCredentialTypeTableData(tableRequest: TableRequestDTO): Promise<TableResponseDTO<ThirdPartyCredentialTypeDTO>> {
        let url = `api/thirdPartyCredentialType`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, TableResponseDTO<ThirdPartyCredentialTypeDTO>>(tableRequest, 'post', url, true, false);
    }

    // post: api/thirdPartyCredentialType/export
    public getThirdPartyCredentialTypeDataTableExport(tableRequest: TableRequestDTO): Promise<string> {
        let url = `api/thirdPartyCredentialType/export`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, string>(tableRequest, 'post', url, true, false);
    }

    // get: api/thirdPartyCredentialType/${id}
    public getThirdPartyCredentialTypeDetails(id: number): Promise<GetThirdPartyCredentialTypeDetailsHandler.Result> {
        let url = `api/thirdPartyCredentialType/${id}`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetThirdPartyCredentialTypeDetailsHandler.Result>(null, 'get', url, true, false);
    }

    // post: api/thirdPartyCredentialType/save
    public saveThirdPartyCredentialTypeDetails(saveThirdPartyCredentialTypeDetails: SaveThirdPartyCredentialTypeDetailsHandler.Request): Promise<SaveThirdPartyCredentialTypeDetailsHandler.Result> {
        let url = `api/thirdPartyCredentialType/save`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, SaveThirdPartyCredentialTypeDetailsHandler.Result>(saveThirdPartyCredentialTypeDetails, 'post', url, true, false);
    }

    // post: api/thirdPartyCredentialType/getThirdPartyCredentialTypes?isSecondaryProgram=${isSecondaryProgram}
    public getThirdPartyCredentialTypes(isSecondaryProgram: boolean | null): Promise<GetThirdPartyCredentialTypeHandler.Result> {
        let url = `api/thirdPartyCredentialType/getThirdPartyCredentialTypes`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (isSecondaryProgram != null) {
            url += `${prefix}isSecondaryProgram=${isSecondaryProgram}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetThirdPartyCredentialTypeHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/thirdPartyCredentialType/getMaxSecondaryThirdPartyCredential?academicYear=${academicYear}
    public getMaxSecondaryThirdPartyCredentials(academicYear: number): Promise<GetMaxSecondaryThirdPartyCredentialsHandler.Result> {
        let url = `api/thirdPartyCredentialType/getMaxSecondaryThirdPartyCredential`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (academicYear != null) {
            url += `${prefix}academicYear=${academicYear}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GetMaxSecondaryThirdPartyCredentialsHandler.Result>(null, 'post', url, true, false);
    }

    // post: api/thirdPartyCredentialType/deleteThirdPartyCredentialType
    public deleteThirdPartyCredentialType(deleteThirdPartyCredentialType: DeleteThirdPartyCredentialTypeHandler.Request): Promise<DeleteThirdPartyCredentialTypeHandler.Result> {
        let url = `api/thirdPartyCredentialType/deleteThirdPartyCredentialType`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeleteThirdPartyCredentialTypeHandler.Result>(deleteThirdPartyCredentialType, 'post', url, true, false);
    }

    // get: api/thirdPartyCredentialType/postSecondaryThirdPartyCredentialImporter
    public postSecondaryThirdPartyCredentialImporter(): Promise<any> {
        let url = `api/thirdPartyCredentialType/postSecondaryThirdPartyCredentialImporter`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, any>(null, 'get', url, true, false);
    }
}
var service = new ThirdPartyCredentialTypeApiService();
export default service;
