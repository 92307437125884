// This file was AUTO-GENERATED by the Typewriter Visual Studio plugin base on the HandlerModels.tst template. 
// !!Do not modify this file or change may be lost!!
// SaveSelfStudySectionHandler
/* eslint-disable */
// @ts-ignore
import * as moment from 'moment';
import SelfStudySectionResponseDTO from '../models/SelfStudySectionResponseDTO';
import SelfStudyResponseDTO from '../models/SelfStudyResponseDTO';
import ResultDTO from '../models/ResultDTO';

interface CompleteSelfStudyEmailQueryParameters {
    academicYearId: number;
    districtId: string | null;
}

const CompleteSelfStudyEmailQueryParameters = {
    create: (initValues?: Partial<CompleteSelfStudyEmailQueryParameters> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            academicYearId: 0,
            districtId: '00000000-0000-0000-0000-000000000000',
        },
        initValues);
    },
    academicYearId: 'academicYearId',
    districtId: 'districtId',
}

interface Request {
    academicYear: number;
    programStatusTypeId: number;
    deniedApproval: boolean | null | null;
    selfStudySectionResponse: SelfStudySectionResponseDTO | null;
    selfStudyResponse: SelfStudyResponseDTO | null;
    saveAndContinue: boolean;
    submit: boolean;
}

const Request = {
    create: (initValues?: Partial<Request> | Record<string, unknown> | null) => {
        return Object.assign(
        {
            academicYear: 0,
            programStatusTypeId: 0,
            deniedApproval: null,
            selfStudySectionResponse: null,
            selfStudyResponse: null,
            saveAndContinue: false,
            submit: false,
        },
        initValues);
    },
    academicYear: 'academicYear',
    programStatusTypeId: 'programStatusTypeId',
    deniedApproval: 'deniedApproval',
    selfStudySectionResponse: 'selfStudySectionResponse',
    selfStudyResponse: 'selfStudyResponse',
    saveAndContinue: 'saveAndContinue',
    submit: 'submit',
}

interface Result extends ResultDTO {
    selfStudySection: SelfStudySectionResponseDTO | null;
    denyApproval: boolean;
}

const Result = {
    create: (initValues?: Partial<Result> | Record<string, unknown> | null) => {
        return Object.assign(ResultDTO.create(),
        {
            selfStudySection: null,
            denyApproval: false,
        },
        initValues);
    },
    selfStudySection: 'selfStudySection',
    denyApproval: 'denyApproval',
}

export { CompleteSelfStudyEmailQueryParameters, Request, Result };